"use client"

import Head from "next/head";
import ToastContainer from "@/components/ToastService";
import { ChildContainerProps } from "@/types/types";

const LandingLayout = (props: ChildContainerProps) => {
  
  return (
    <>
      <Head>
          <title>Oxion | Your ideas Alive</title>
      </Head>
      <ToastContainer />
      <div className={'landing'}>
        {props.children}
      </div>
    </>
  );
};

export default LandingLayout;
